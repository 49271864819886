import * as React from "react";
import { useEffect } from "react";
import "./layout/styles.scss";
import {
  TitleSection,
  SEOMetaTags,
  BlogBreadcrumbs,
  CallToAction,
  GreyFooter,
  FundingBanner,
  PrevButton,
  NextButton
} from "../components";
import CategoryTabs from "../components/CategoryTabs/CategoryTabs";
import BusinessUseCaseCard from "../components/BusinessUseCaseCard/BusinessUseCaseCard";
import MainMenu from "../components/MainMenu/MainMenu";
import { IUseCaseList } from "./interfaces";

const BusinessUseCaseList = (data: IUseCaseList): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  let { hasNextPage, hasPrevPage, nextPage, prevPage, items, seoMetaTags, menu, footer, lang, urlName, cta, c2c, pageTitle, banner, categoriesData } = data.pageContext;
  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "business-use-case");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;

  const alternateCategoryData = items[0].article._allTagsLocales.filter(el => el.locale !== lang);
  const alternateLinks = alternateCategoryData && alternateCategoryData.map(el => el && {
    locale: el.locale,
    link: `https://pushpushgo.com/${el.locale}/${urlName}/1/`,
  });

  return (
    <>
      <MainMenu menu={menu} lang={lang} c2c={c2c}/>
      <SEOMetaTags tags={seoMetaTags} lang={lang} pageName={pageName} alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: `${pageName}` }, {
        key: "blog",
        value: "blog-version",
      }, { key: "color", value: "green-version" }, { key: "paragraph", value: `${description}` }]}/>
      <section className="subpage-section">
        <div className="content-limiter">
          <BlogBreadcrumbs pages={[{ key: "page", value: `${pageName}`, url: `/${lang}/${urlName}/1` }]}/>
          {categoriesData && categoriesData.length > 0 &&
          <CategoryTabs items={categoriesData} url={`${lang}/${urlName}/category/`}/>}
          {items && <div className="cards-grid">
            {items.map(article => article.article.title &&
              <BusinessUseCaseCard key={Math.random() + new Date().toDateString()} {...article} lang={lang}/>)}
          </div>}
          <nav>
            {hasPrevPage && <PrevButton lang={lang} path={`/${lang}/${urlName}/${prevPage}`}/>}
            {hasNextPage && <NextButton lang={lang} path={`/${lang}/${urlName}/${nextPage}`}/>}
          </nav>
        </div>
      </section>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};
export default BusinessUseCaseList;
