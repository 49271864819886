import React from "react";
import "./CategoryTabs.scss";
import Link from 'gatsby-link';
import { slugify } from "../../helpers/helpers";

const CategoryTabs = (data) => (
  <div className="category-tabs">
    {data && data.items.map(item => {
      return <div className="hover" key={Math.random() + new Date().toDateString()}>
        <Link className="on-hover-show" style={{backgroundColor: `${item[1].color.hex}`}} to={`/${data.url}${slugify(item[0])}/1`}>{item[0]}</Link>
        <Link className="on-hover-hide" style={{borderBottom: `2px solid ${item[1].color.hex}`}} to={`/${data.url}${slugify(item[0])}/1`}>{item[0]}</Link>
      </div>
    })}
  </div>
);

export default CategoryTabs;
