import React from "react";
import "./BusinessUseCaseCard.scss";
import { slugify } from "../../helpers/helpers";
import { GatsbyImage } from "gatsby-plugin-image";

const BusinessUseCaseCard = (props) => {

  const { tags, title, backgroundImage, slug, sneakPeak } = props.article;
  const lang = props.lang;
  let btnContent;

  switch (lang) {
    case "pl":
      btnContent = "Czytaj dalej >";
      break;
    case "en":
      btnContent = "Read more >";
      break;
    case "es":
      btnContent = "Lee más >";
      break;
    default:
      btnContent = "Read more >";
  }

  const postTags = [].concat(tags);

  return (
    <div className="business-use-case-item">
      <a href={`/${lang}/business-use-case/${slug}`}>
        <GatsbyImage className="business-use-case-image"
                     alt={backgroundImage.alt || ""}
                     image={backgroundImage.gatsbyImageData}/>
      </a>
      <div className="business-use-case-text">
        <div>
          {postTags && postTags.map(tag => <a href={`/${lang}/business-use-case/category/${slugify(tag.name)}/1`}
                                              key={Math.random() + new Date().toDateString()}
                                              className="post-tag">{tag.name}</a>)}
        </div>
        <a className="business-use-case-title" href={`/${lang}/business-use-case/${slug}`}>{title}</a>
        {sneakPeak && <p className="business-use-case-description">{sneakPeak}</p>}
        <div className="business-use-case-btn-wrapper">
          <a href={`/${lang}/business-use-case/${slug}`} className="btn business-use-case-btn">{btnContent}</a>
        </div>
      </div>
    </div>
  );
};

export default BusinessUseCaseCard;
