import * as  React from "react";
import Link from "gatsby-link";

interface IPaginatedButton {
  lang: string;
  path: string;
}

export const PrevButton = ({ lang, path }: IPaginatedButton): JSX.Element => {
  let prevPageText: string;

  switch (lang) {
    case "pl":
      prevPageText = "< Poprzednia strona";
      break;
    case "en":
      prevPageText = "< Previous Page";
      break;
    case "es":
      prevPageText = "< Página anterior";
      break;
    default:
      prevPageText = "< Previous Page";
  }

  return <Link rel="prev" className="page-prev" to={path}>{prevPageText}</Link>;
};

export const NextButton = ({ lang, path }: IPaginatedButton): JSX.Element => {
  let nextPageText: string;

  switch (lang) {
    case "pl":
      nextPageText = "Następna strona >";
      break;
    case "en":
      nextPageText = "Next Page >";
      break;
    case "es":
      nextPageText = "Página siguiente >";
      break;
    default:
      nextPageText = "Next Page >";
  }

  return <Link rel="next" className="page-next" to={path}>{nextPageText}</Link>;
};
