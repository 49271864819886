import * as React from "react";
import "../EBookCards/EBookCard.scss";
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Markdown from 'react-markdown';

interface IInterviewCard {
  lang: string;
  interviewDescription: string;
  interviewTitle: string;
  interviewLanding: string;
  interviewImage: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  }
}

export const InterviewCard = ({ lang, interviewDescription, interviewImage, interviewLanding, interviewTitle }: IInterviewCard): JSX.Element => {

  const btnContent = lang && lang === "pl" ? "Obejrzyj >" : "Watch now >";

  return <div className="e-book-card">
    <a href={ interviewLanding }>
      { interviewImage && interviewImage.gatsbyImageData && <GatsbyImage image={ interviewImage.gatsbyImageData }
                                                                         className={ "e-book-card-image" }
                                                                         alt={ interviewImage.alt ? interviewImage.alt : "" }/> }
    </a>
    <div className="e-book-card-text">
      <a className="e-book-card-title" href={ interviewLanding } rel="noreferrer"
         target="_blank">{ interviewTitle }</a>
      <Markdown className="e-book-card-description" children={ interviewDescription }/>
      <div className="e-book-card-info">
        <a href={ interviewLanding } rel="noreferrer" className="e-book-button btn">{ btnContent }</a>
      </div>
    </div>
  </div>
}
